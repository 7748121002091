import { Inject, Injectable } from '@angular/core'

import { Actions, createEffect, ofType } from '@ngrx/effects'
import { of } from 'rxjs'
import { catchError, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators'

import { PerformanceApiService } from '~/core/api'

import * as PERFORMANCE_ACTIONS from './performance.actions'
import { MINIMUM_COMPANY_COUNT, QUARTER_TOTAL_LIST_COUNT } from './performance.constant'
import { IPerformanceFacade, PERFORMANCE_FACADE } from './performance.facade'
import { IPerformanceQuarter } from './performance.model'

@Injectable()
export class PerformanceEffects {
  constructor(
    private actions$: Actions,
    private performanceApiService: PerformanceApiService,
    @Inject(PERFORMANCE_FACADE) private performanceFacade: IPerformanceFacade
  ) {}

  getPerformanceQuarterList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PERFORMANCE_ACTIONS.getPerformanceQuarterList),
      mergeMap(() => this.performanceFacade.summaryFlag$),
      switchMap(summaryFlag =>
        this.performanceApiService.getPerformanceQuarterList(summaryFlag).pipe(
          withLatestFrom(this.performanceFacade.quarter$),
          mergeMap(([quarterList, lastQuarter]) => {
            const quarter = this.getQuarter(quarterList, lastQuarter)

            return [
              PERFORMANCE_ACTIONS.getPerformanceQuarterListSuccess({ quarterList }),
              PERFORMANCE_ACTIONS.setPerformanceQuarter({ quarter }),
              PERFORMANCE_ACTIONS.setDefaultPerformanceQuarter({ quarter }),
            ]
          }),
          catchError(error => [PERFORMANCE_ACTIONS.getPerformanceQuarterListError({ error })])
        )
      )
    )
  )

  // Quarter Total List
  getPerformanceQuarterTotalList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PERFORMANCE_ACTIONS.getPerformanceQuarterTotalList),
      switchMap(({ quarter, year, summaryFlag }) =>
        this.performanceApiService
          .getPerformanceQuarterTotalList(quarter, year, QUARTER_TOTAL_LIST_COUNT, summaryFlag)
          .pipe(
            mergeMap(performanceQuarterTotal => [
              PERFORMANCE_ACTIONS.getPerformanceQuarterTotalListSuccess({ performanceQuarterTotal }),
            ]),
            catchError(error => [PERFORMANCE_ACTIONS.getPerformanceQuarterTotalListError({ error })])
          )
      )
    )
  )

  // MktCap
  getPerformanceMktCap$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PERFORMANCE_ACTIONS.getPerformanceMktCap),
      withLatestFrom(
        this.performanceFacade.quarter$,
        this.performanceFacade.mktCapQuarter$,
        this.performanceFacade.mktCapData$,
        this.performanceFacade.mktCapSortBy$,
        this.performanceFacade.mktCapOrder$
      ),
      switchMap(([{ sortBy, order }, quarter, mktCapQuarter, mktCapData, mktCapSortBy, mktCapOrder]) =>
        // Don't reload data if exist for the same quarter
        (quarter && (quarter !== mktCapQuarter || sortBy !== mktCapSortBy || order !== mktCapOrder)
          ? this.performanceApiService.getPerformanceMktCap(quarter.year, quarter.quarter, sortBy, order)
          : of({
              order,
              sortBy,
              data: mktCapData || [],
            })
        ).pipe(
          mergeMap(({ data, sortBy, order }) => [
            PERFORMANCE_ACTIONS.getPerformanceMktCapSuccess({ data, quarter: quarter!, sortBy, order }),
          ]),
          catchError(error => [PERFORMANCE_ACTIONS.getPerformanceMktCapError({ error })])
        )
      )
    )
  )

  // Sector
  getPerformanceSector$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PERFORMANCE_ACTIONS.getPerformanceSector),
      withLatestFrom(
        this.performanceFacade.quarter$,
        this.performanceFacade.sectorQuarter$,
        this.performanceFacade.sectorData$,
        this.performanceFacade.sectorSortBy$,
        this.performanceFacade.sectorOrder$
      ),
      switchMap(([{ sortBy, order }, quarter, sectorQuarter, sectorData, sectorSortBy, sectorOrder]) =>
        // Don't reload data if exist for the same quarter
        (quarter && (quarter !== sectorQuarter || sortBy !== sectorSortBy || order !== sectorOrder)
          ? this.performanceApiService.getPerformanceSector(quarter.year, quarter.quarter, sortBy, order)
          : of({
              order,
              sortBy,
              data: sectorData || [],
            })
        ).pipe(
          mergeMap(({ data, sortBy, order }) => [
            PERFORMANCE_ACTIONS.getPerformanceSectorSuccess({ data, quarter: quarter!, sortBy, order }),
          ]),
          catchError(error => [PERFORMANCE_ACTIONS.getPerformanceSectorError({ error })])
        )
      )
    )
  )

  // CntEst
  getPerformanceCntEst$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PERFORMANCE_ACTIONS.getPerformanceCntEst),
      withLatestFrom(
        this.performanceFacade.quarter$,
        this.performanceFacade.cntEstQuarter$,
        this.performanceFacade.cntEstData$,
        this.performanceFacade.cntEstSortBy$,
        this.performanceFacade.cntEstOrder$
      ),
      switchMap(([{ sortBy, order }, quarter, cntEstQuarter, cntEstData, cntEstSortBy, cntEstOrder]) =>
        // Don't reload data if exist for the same quarter
        (quarter && (quarter !== cntEstQuarter || sortBy !== cntEstSortBy || order !== cntEstOrder)
          ? this.performanceApiService.getPerformanceCntEst(quarter.year, quarter.quarter, sortBy, order)
          : of({
              order,
              sortBy,
              data: cntEstData || [],
            })
        ).pipe(
          mergeMap(({ data, sortBy, order }) => [
            PERFORMANCE_ACTIONS.getPerformanceCntEstSuccess({ data, quarter: quarter!, sortBy, order }),
          ]),
          catchError(error => [PERFORMANCE_ACTIONS.getPerformanceCntEstError({ error })])
        )
      )
    )
  )

  private getQuarter(
    quarterList: IPerformanceQuarter[],
    lastQuarter?: IPerformanceQuarter | null
  ): IPerformanceQuarter | null {
    if (!quarterList.length) {
      return null
    }

    const existQuarter =
      lastQuarter && quarterList.find(q => q.year === lastQuarter.year && q.quarter === lastQuarter.quarter)

    if (existQuarter) {
      return existQuarter
    }

    for (let i = quarterList.length - 1; i >= 0; i--) {
      if (quarterList[i].companyCount >= MINIMUM_COMPANY_COUNT) {
        return quarterList[i]
      }
    }
    return quarterList[0]
  }
}
