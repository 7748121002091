import { Action, createReducer, on } from '@ngrx/store'

import * as PERFORMANCE_ACTIONS from './performance.actions'
import { createPerformanceInitState, IPerformanceState } from './performance.state'

export const performanceState = createReducer(
  createPerformanceInitState(),

  on(PERFORMANCE_ACTIONS.getPerformanceQuarterList, state => ({ ...state, inRequest: true })),
  on(PERFORMANCE_ACTIONS.getPerformanceQuarterListSuccess, (state, { quarterList }) => ({
    ...state,
    inRequest: false,
    inError: false,
    data: { ...state.data, quarterList },
  })),
  on(PERFORMANCE_ACTIONS.getPerformanceQuarterListError, (state, { error }) => ({
    ...state,
    inRequest: false,
    inError: true,
    data: {
      ...state.data,
      error,
    },
  })),
  on(PERFORMANCE_ACTIONS.setPerformanceQuarter, (state, { quarter }) => ({
    ...state,
    data: { ...state.data, quarter },
  })),
  on(PERFORMANCE_ACTIONS.setSummaryFlag, (state, { summaryFlag }) => ({
    ...state,
    data: { ...state.data, summaryFlag },
  })),
  on(PERFORMANCE_ACTIONS.setDefaultPerformanceQuarter, (state, { quarter }) => ({
    ...state,
    data: { ...state.data, defaultQuarter: quarter },
  })),

  // Quarter Total List
  on(PERFORMANCE_ACTIONS.getPerformanceQuarterTotalList, state => ({
    ...state,
    data: {
      ...state.data,
      performanceQuarterTotalList: {
        ...state.data.performanceQuarterTotalList,
        inRequest: true,
      },
    },
  })),
  on(PERFORMANCE_ACTIONS.getPerformanceQuarterTotalListSuccess, (state, { performanceQuarterTotal: data }) => ({
    ...state,
    inRequest: false,
    inError: false,
    data: {
      ...state.data,
      performanceQuarterTotalList: {
        inRequest: false,
        data,
      },
    },
  })),
  on(PERFORMANCE_ACTIONS.getPerformanceQuarterTotalListError, (state, { error }) => ({
    ...state,
    data: {
      ...state.data,
      performanceQuarterTotalList: {
        inRequest: false,
        error,
      },
    },
  })),

  // MktCap
  on(PERFORMANCE_ACTIONS.getPerformanceMktCap, state => ({
    ...state,
    data: {
      ...state.data,
      performanceMktCap: {
        ...state.data.performanceMktCap,
        inRequest: true,
      },
    },
  })),
  on(PERFORMANCE_ACTIONS.getPerformanceMktCapSuccess, (state, { data, quarter, order, sortBy }) => ({
    ...state,
    inRequest: false,
    inError: false,
    data: {
      ...state.data,
      performanceMktCap: {
        inRequest: false,
        quarter,
        sortBy,
        order,
        data,
      },
    },
  })),
  on(PERFORMANCE_ACTIONS.getPerformanceMktCapError, (state, { error }) => ({
    ...state,
    data: {
      ...state.data,
      performanceMktCap: {
        inRequest: false,
        error,
      },
    },
  })),

  // Sector
  on(PERFORMANCE_ACTIONS.getPerformanceSector, state => ({
    ...state,
    data: {
      ...state.data,
      performanceSector: {
        ...state.data.performanceSector,
        inRequest: true,
      },
    },
  })),
  on(PERFORMANCE_ACTIONS.getPerformanceSectorSuccess, (state, { data, quarter, order, sortBy }) => ({
    ...state,
    inRequest: false,
    inError: false,
    data: {
      ...state.data,
      performanceSector: {
        inRequest: false,
        quarter,
        sortBy,
        order,
        data,
      },
    },
  })),
  on(PERFORMANCE_ACTIONS.getPerformanceSectorError, (state, { error }) => ({
    ...state,
    data: {
      ...state.data,
      performanceSector: {
        inRequest: false,
        error,
      },
    },
  })),

  // CntEst
  on(PERFORMANCE_ACTIONS.getPerformanceCntEst, state => ({
    ...state,
    data: {
      ...state.data,
      performanceCntEst: {
        ...state.data.performanceCntEst,
        inRequest: true,
      },
    },
  })),
  on(PERFORMANCE_ACTIONS.getPerformanceCntEstSuccess, (state, { data, quarter, order, sortBy }) => ({
    ...state,
    inRequest: false,
    inError: false,
    data: {
      ...state.data,
      performanceCntEst: {
        inRequest: false,
        quarter,
        sortBy,
        order,
        data,
      },
    },
  })),
  on(PERFORMANCE_ACTIONS.getPerformanceCntEstError, (state, { error }) => ({
    ...state,
    data: {
      ...state.data,
      performanceCntEst: {
        inRequest: false,
        error,
      },
    },
  }))
)

export const performanceReducer = (state: IPerformanceState, action: Action): IPerformanceState =>
  performanceState(state, action)
