import { createFeatureSelector, createSelector } from '@ngrx/store'

import { IPerformanceState, performanceFeatureKey } from './performance.state'

export const selectPerformance = createFeatureSelector<IPerformanceState>(performanceFeatureKey)

export const selectPerformanceInRequest = createSelector([selectPerformance], state => state.inRequest)

export const selectPerformanceInError = createSelector([selectPerformance], state => state.inError)

export const selectPerformanceData = createSelector([selectPerformance], state => state.data)

export const selectSummaryFlag = createSelector([selectPerformanceData], state => state.summaryFlag)

export const selectPerformanceQuarterList = createSelector([selectPerformanceData], state => state.quarterList)

export const selectPerformanceQuarter = createSelector([selectPerformanceData], state => state.quarter)

export const selectPerformanceDefaultQuarter = createSelector([selectPerformanceData], state => state.defaultQuarter)

export const selectPerformanceError = createSelector([selectPerformanceData], state => state.error)

// Quarter Total List
export const selectPerformanceQuarterTotal = createSelector(
  [selectPerformanceData],
  state => state.performanceQuarterTotalList
)

export const selectPerformanceQuarterTotalInRequest = createSelector(
  [selectPerformanceQuarterTotal],
  state => state?.inRequest
)

export const selectPerformanceQuarterTotalError = createSelector([selectPerformanceQuarterTotal], state => state?.error)

export const selectPerformanceQuarterTotalData = createSelector([selectPerformanceQuarterTotal], state => state?.data)

// MktCap
export const selectPerformanceMktCap = createSelector([selectPerformanceData], state => state.performanceMktCap)

export const selectPerformanceMktCapInRequest = createSelector([selectPerformanceMktCap], state => state?.inRequest)

export const selectPerformanceMktCapError = createSelector([selectPerformanceMktCap], state => state?.error)

export const selectPerformanceMktCapQuarter = createSelector([selectPerformanceMktCap], state => state?.quarter)

export const selectPerformanceMktCapData = createSelector([selectPerformanceMktCap], state => state?.data)

export const selectPerformanceMktCapSortBy = createSelector([selectPerformanceMktCap], state => state?.sortBy)

export const selectPerformanceMktCapOrder = createSelector([selectPerformanceMktCap], state => state?.order)

// Sector
export const selectPerformanceSector = createSelector([selectPerformanceData], state => state.performanceSector)

export const selectPerformanceSectorInRequest = createSelector([selectPerformanceSector], state => state?.inRequest)

export const selectPerformanceSectorError = createSelector([selectPerformanceSector], state => state?.error)

export const selectPerformanceSectorQuarter = createSelector([selectPerformanceSector], state => state?.quarter)

export const selectPerformanceSectorData = createSelector([selectPerformanceSector], state => state?.data)

export const selectPerformanceSectorSortBy = createSelector([selectPerformanceSector], state => state?.sortBy)

export const selectPerformanceSectorOrder = createSelector([selectPerformanceSector], state => state?.order)

// CntEst
export const selectPerformanceCntEst = createSelector([selectPerformanceData], state => state.performanceCntEst)

export const selectPerformanceCntEstInRequest = createSelector([selectPerformanceCntEst], state => state?.inRequest)

export const selectPerformanceCntEstError = createSelector([selectPerformanceCntEst], state => state?.error)

export const selectPerformanceCntEstQuarter = createSelector([selectPerformanceCntEst], state => state?.quarter)

export const selectPerformanceCntEstData = createSelector([selectPerformanceCntEst], state => state?.data)

export const selectPerformanceCntEstSortBy = createSelector([selectPerformanceCntEst], state => state?.sortBy)

export const selectPerformanceCntEstOrder = createSelector([selectPerformanceCntEst], state => state?.order)
