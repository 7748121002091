import { SUMMARY_FLAG_DEFAULT_VALUE } from './performance.constant'
import {
  EPerformanceSummaryFlag,
  IPerformanceCntEst,
  IPerformanceMktCap,
  IPerformanceQuarter,
  IPerformanceQuarterTotal,
  IPerformanceSector,
} from './performance.model'

export const performanceFeatureKey = 'Performance prox'

export interface IPerformanceState {
  inRequest: boolean
  inError: boolean
  data: {
    summaryFlag: EPerformanceSummaryFlag
    quarterList: IPerformanceQuarter[]
    quarter?: IPerformanceQuarter | null
    defaultQuarter?: IPerformanceQuarter | null
    error?: Error | null
    performanceQuarterTotalList?: {
      inRequest?: boolean
      data?: IPerformanceQuarterTotal[]
      error?: Error | null
    }
    performanceMktCap?: {
      inRequest?: boolean
      quarter?: IPerformanceQuarter
      sortBy?: string
      order?: 'asc' | 'desc' | ''
      data?: IPerformanceMktCap[]
      error?: Error | null
    }
    performanceSector?: {
      inRequest?: boolean
      quarter?: IPerformanceQuarter
      sortBy?: string
      order?: 'asc' | 'desc' | ''
      data?: IPerformanceSector[]
      error?: Error | null
    }
    performanceCntEst?: {
      inRequest?: boolean
      quarter?: IPerformanceQuarter
      sortBy?: string
      order?: 'asc' | 'desc' | ''
      data?: IPerformanceCntEst[]
      error?: Error | null
    }
  }
}

export const createPerformanceInitState = (): IPerformanceState => ({
  inRequest: false,
  inError: false,
  data: {
    summaryFlag: SUMMARY_FLAG_DEFAULT_VALUE,
    quarterList: [],
  },
})

export interface IAppWithPerformanceState {
  performance: IPerformanceState
}

export const createAppWithPerformanceInitState = (): IAppWithPerformanceState => ({
  performance: createPerformanceInitState(),
})
