import { Injectable, InjectionToken } from '@angular/core'

import { Store } from '@ngrx/store'
import { Observable } from 'rxjs'

import * as PERFORMANCE_ACTIONS from './performance.actions'
import {
  EPerformanceSummaryFlag,
  IPerformanceCntEst,
  IPerformanceMktCap,
  IPerformanceQuarter,
  IPerformanceQuarterTotal,
  IPerformanceSector,
} from './performance.model'
import {
  selectPerformanceCntEstData,
  selectPerformanceCntEstError,
  selectPerformanceCntEstInRequest,
  selectPerformanceCntEstOrder,
  selectPerformanceCntEstQuarter,
  selectPerformanceCntEstSortBy,
  selectPerformanceDefaultQuarter,
  selectPerformanceError,
  selectPerformanceInError,
  selectPerformanceInRequest,
  selectPerformanceMktCapData,
  selectPerformanceMktCapError,
  selectPerformanceMktCapInRequest,
  selectPerformanceMktCapOrder,
  selectPerformanceMktCapQuarter,
  selectPerformanceMktCapSortBy,
  selectPerformanceQuarter,
  selectPerformanceQuarterList,
  selectPerformanceQuarterTotalData,
  selectPerformanceQuarterTotalError,
  selectPerformanceQuarterTotalInRequest,
  selectPerformanceSectorData,
  selectPerformanceSectorError,
  selectPerformanceSectorInRequest,
  selectPerformanceSectorOrder,
  selectPerformanceSectorQuarter,
  selectPerformanceSectorSortBy,
  selectSummaryFlag,
} from './performance.selectors'
import { IAppWithPerformanceState } from './performance.state'

export interface IPerformanceFacade {
  inRequest$: Observable<boolean>
  inError$: Observable<boolean>
  error$: Observable<Error | null | undefined>

  summaryFlag$: Observable<EPerformanceSummaryFlag>
  quarterList$: Observable<IPerformanceQuarter[]>
  quarter$: Observable<IPerformanceQuarter | null | undefined>
  defaultQuarter$: Observable<IPerformanceQuarter | null | undefined>

  quarterTotalInRequest$: Observable<boolean | undefined>
  quarterTotalError$: Observable<Error | null | undefined>
  quarterTotalData$: Observable<IPerformanceQuarterTotal[] | undefined>

  mktCapInRequest$: Observable<boolean | undefined>
  mktCapError$: Observable<Error | null | undefined>
  mktCapQuarter$: Observable<IPerformanceQuarter | undefined>
  mktCapData$: Observable<IPerformanceMktCap[] | undefined>
  mktCapSortBy$: Observable<string | undefined>
  mktCapOrder$: Observable<'asc' | 'desc' | '' | undefined>

  sectorInRequest$: Observable<boolean | undefined>
  sectorError$: Observable<Error | null | undefined>
  sectorQuarter$: Observable<IPerformanceQuarter | undefined>
  sectorData$: Observable<IPerformanceSector[] | undefined>
  sectorSortBy$: Observable<string | undefined>
  sectorOrder$: Observable<'asc' | 'desc' | '' | undefined>

  cntEstInRequest$: Observable<boolean | undefined>
  cntEstError$: Observable<Error | null | undefined>
  cntEstQuarter$: Observable<IPerformanceQuarter | undefined>
  cntEstData$: Observable<IPerformanceCntEst[] | undefined>
  cntEstSortBy$: Observable<string | undefined>
  cntEstOrder$: Observable<'asc' | 'desc' | '' | undefined>

  getPerformanceQuarterList(): void
  getPerformanceQuarterTotalList(year: number, quarter: number, summaryFlag: EPerformanceSummaryFlag): void
  changeQuarter(quarter: IPerformanceQuarter): void
  changeSummaryFlag(summaryFlag: EPerformanceSummaryFlag): void
  getPerformanceMktCap(sortBy: string, order: 'asc' | 'desc' | ''): void
  getPerformanceSector(sortBy: string, order: 'asc' | 'desc' | ''): void
  getPerformanceCntEst(sortBy: string, order: 'asc' | 'desc' | ''): void
}

export const PERFORMANCE_FACADE = new InjectionToken<IPerformanceFacade>('PERFORMANCE_FACADE')

@Injectable()
export class PerformanceFacade implements IPerformanceFacade {
  inRequest$ = this.store$.select(selectPerformanceInRequest)
  inError$ = this.store$.select(selectPerformanceInError)
  error$ = this.store$.select(selectPerformanceError)

  summaryFlag$ = this.store$.select(selectSummaryFlag)
  quarterList$ = this.store$.select(selectPerformanceQuarterList)
  quarter$ = this.store$.select(selectPerformanceQuarter)
  defaultQuarter$ = this.store$.select(selectPerformanceDefaultQuarter)

  quarterTotalInRequest$ = this.store$.select(selectPerformanceQuarterTotalInRequest)
  quarterTotalError$ = this.store$.select(selectPerformanceQuarterTotalError)
  quarterTotalData$ = this.store$.select(selectPerformanceQuarterTotalData)

  mktCapInRequest$ = this.store$.select(selectPerformanceMktCapInRequest)
  mktCapError$ = this.store$.select(selectPerformanceMktCapError)
  mktCapQuarter$ = this.store$.select(selectPerformanceMktCapQuarter)
  mktCapData$ = this.store$.select(selectPerformanceMktCapData)
  mktCapSortBy$ = this.store$.select(selectPerformanceMktCapSortBy)
  mktCapOrder$ = this.store$.select(selectPerformanceMktCapOrder)

  sectorInRequest$ = this.store$.select(selectPerformanceSectorInRequest)
  sectorError$ = this.store$.select(selectPerformanceSectorError)
  sectorQuarter$ = this.store$.select(selectPerformanceSectorQuarter)
  sectorData$ = this.store$.select(selectPerformanceSectorData)
  sectorSortBy$ = this.store$.select(selectPerformanceSectorSortBy)
  sectorOrder$ = this.store$.select(selectPerformanceSectorOrder)

  cntEstInRequest$ = this.store$.select(selectPerformanceCntEstInRequest)
  cntEstError$ = this.store$.select(selectPerformanceCntEstError)
  cntEstQuarter$ = this.store$.select(selectPerformanceCntEstQuarter)
  cntEstData$ = this.store$.select(selectPerformanceCntEstData)
  cntEstSortBy$ = this.store$.select(selectPerformanceCntEstSortBy)
  cntEstOrder$ = this.store$.select(selectPerformanceCntEstOrder)

  constructor(private store$: Store<IAppWithPerformanceState>) {}

  getPerformanceQuarterList(): void {
    this.store$.dispatch(PERFORMANCE_ACTIONS.getPerformanceQuarterList())
  }

  getPerformanceQuarterTotalList(year: number, quarter: number, summaryFlag: EPerformanceSummaryFlag): void {
    this.store$.dispatch(PERFORMANCE_ACTIONS.getPerformanceQuarterTotalList({ year, quarter, summaryFlag }))
  }

  changeQuarter(quarter: IPerformanceQuarter): void {
    this.store$.dispatch(PERFORMANCE_ACTIONS.setPerformanceQuarter({ quarter }))
  }

  changeSummaryFlag(summaryFlag: EPerformanceSummaryFlag): void {
    this.store$.dispatch(PERFORMANCE_ACTIONS.setSummaryFlag({ summaryFlag }))
  }

  getPerformanceMktCap(sortBy: string, order: 'asc' | 'desc' | ''): void {
    this.store$.dispatch(PERFORMANCE_ACTIONS.getPerformanceMktCap({ sortBy, order }))
  }

  getPerformanceSector(sortBy: string, order: 'asc' | 'desc' | ''): void {
    this.store$.dispatch(PERFORMANCE_ACTIONS.getPerformanceSector({ sortBy, order }))
  }

  getPerformanceCntEst(sortBy: string, order: 'asc' | 'desc' | ''): void {
    this.store$.dispatch(PERFORMANCE_ACTIONS.getPerformanceCntEst({ sortBy, order }))
  }
}
