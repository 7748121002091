import { NgModule } from '@angular/core'

import { EffectsModule } from '@ngrx/effects'
import { StoreModule } from '@ngrx/store'
import { TranslateModule } from '@ngx-translate/core'

import { PerformanceEffects } from './performance.effects'
import { PERFORMANCE_FACADE, PerformanceFacade } from './performance.facade'
import { performanceReducer } from './performance.reducer'
import { performanceFeatureKey } from './performance.state'

const EFFECTS = [PerformanceEffects]

@NgModule({
  imports: [
    StoreModule.forFeature(performanceFeatureKey, performanceReducer),
    EffectsModule.forFeature(EFFECTS),
    TranslateModule,
  ],
  providers: [...EFFECTS, { provide: PERFORMANCE_FACADE, useClass: PerformanceFacade }],
})
export class PerformanceModule {}
