export enum EPerformanceSummaryFlag {
  SUMMARY_COLUMN = 'Y',
  RESULTS_COLUMN = 'O',
  CASUAL_COLUMN = 'N',
}

export interface IPerformanceCore {
  epsWeBeatThem: number
  epsErrorReduction: number
  revWeBeatThem: number
  revErrorReduction: number
  summaryFlag: EPerformanceSummaryFlag
}

export interface IPerformanceQuarter {
  year: number
  quarter: number
  epsWeBeatThem: number
  revWeBeatThem: number
  companyCount: number
  quarterDate: string
}

export interface IPerformanceQuarterTotal extends IPerformanceQuarter {}

export interface IPerformanceMktCap extends IPerformanceCore {
  mktCapFrom: number
  mktCapTo: number
  companyCount: number
}

export interface IPerformanceSector extends IPerformanceCore {
  sector: string
  companyCount: number
}

export interface IPerformanceCntEst extends IPerformanceCore {
  cntEstFrom: number
  cntEstTo: number
  companyCount: number
}
