import { createAction, props } from '@ngrx/store'

import {
  EPerformanceSummaryFlag,
  IPerformanceCntEst,
  IPerformanceMktCap,
  IPerformanceQuarter,
  IPerformanceQuarterTotal,
  IPerformanceSector,
} from './performance.model'
import { performanceFeatureKey } from './performance.state'

export const getPerformanceQuarterList = createAction(`[${performanceFeatureKey}] Get Performance Quarter List`)
export const getPerformanceQuarterListSuccess = createAction(
  `[${performanceFeatureKey}] Get Performance Quarter List Success`,
  props<{ quarterList: IPerformanceQuarter[] }>()
)
export const getPerformanceQuarterListError = createAction(
  `[${performanceFeatureKey}] Get Performance Quarter List Error`,
  props<{ error: Error }>()
)
export const setPerformanceQuarter = createAction(
  `[${performanceFeatureKey}] Set Performance Quarter`,
  props<{ quarter: IPerformanceQuarter | null }>()
)

export const setSummaryFlag = createAction(
  `[${performanceFeatureKey}] Set Summary Flag`,
  props<{ summaryFlag: EPerformanceSummaryFlag }>()
)

export const setDefaultPerformanceQuarter = createAction(
  `[${performanceFeatureKey}] Set Default Performance Quarter`,
  props<{ quarter: IPerformanceQuarter | null }>()
)

// Quarter Total List
export const getPerformanceQuarterTotalList = createAction(
  `[${performanceFeatureKey}] Get Performance Quarter Total List`,
  props<{
    year: number
    quarter: number
    summaryFlag: EPerformanceSummaryFlag
  }>()
)
export const getPerformanceQuarterTotalListSuccess = createAction(
  `[${performanceFeatureKey}] Get Performance Quarter Total List Success`,
  props<{ performanceQuarterTotal: IPerformanceQuarterTotal[] }>()
)
export const getPerformanceQuarterTotalListError = createAction(
  `[${performanceFeatureKey}] Get Performance Quarter Total List Error`,
  props<{ error: Error }>()
)

// MktCap
export const getPerformanceMktCap = createAction(
  `[${performanceFeatureKey}] Get Performance MktCap List`,
  props<{
    sortBy: string
    order: 'asc' | 'desc' | ''
  }>()
)
export const getPerformanceMktCapSuccess = createAction(
  `[${performanceFeatureKey}] Get Performance MktCap List Success`,
  props<{
    data: IPerformanceMktCap[]
    quarter: IPerformanceQuarter
    sortBy: string
    order: 'asc' | 'desc' | ''
  }>()
)
export const getPerformanceMktCapError = createAction(
  `[${performanceFeatureKey}] Get Performance MktCap List Error`,
  props<{ error: Error }>()
)

// Sector
export const getPerformanceSector = createAction(
  `[${performanceFeatureKey}] Get Performance Sector List`,
  props<{
    sortBy: string
    order: 'asc' | 'desc' | ''
  }>()
)
export const getPerformanceSectorSuccess = createAction(
  `[${performanceFeatureKey}] Get Performance Sector List Success`,
  props<{
    data: IPerformanceSector[]
    quarter: IPerformanceQuarter
    sortBy: string
    order: 'asc' | 'desc' | ''
  }>()
)
export const getPerformanceSectorError = createAction(
  `[${performanceFeatureKey}] Get Performance Sector List Error`,
  props<{ error: Error }>()
)

// CntEst
export const getPerformanceCntEst = createAction(
  `[${performanceFeatureKey}] Get Performance Cnt Est List`,
  props<{
    sortBy: string
    order: 'asc' | 'desc' | ''
  }>()
)
export const getPerformanceCntEstSuccess = createAction(
  `[${performanceFeatureKey}] Get Performance CntEst List Success`,
  props<{
    data: IPerformanceCntEst[]
    quarter: IPerformanceQuarter
    sortBy: string
    order: 'asc' | 'desc' | ''
  }>()
)
export const getPerformanceCntEstError = createAction(
  `[${performanceFeatureKey}] Get Performance Cnt Est List Error`,
  props<{ error: Error }>()
)
