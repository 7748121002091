import { EPerformanceSummaryFlag } from './performance.model'

export const MINIMUM_COMPANY_COUNT = 350
export const QUARTER_TOTAL_LIST_COUNT = 6

export const DEFAULT_MKT_CAP_SORT_BY = 'mktCapFrom'
export const DEFAULT_MKT_CAP_ORDER: 'asc' | 'desc' = 'desc'

export const DEFAULT_SECTOR_SORT_BY = 'sector'
export const DEFAULT_SECTOR_ORDER: 'asc' | 'desc' = 'asc'

export const DEFAULT_CNT_EST_SORT_BY = ''
export const DEFAULT_CNT_EST_ORDER: 'asc' | 'desc' = 'desc'

export const SUMMARY_FLAGS_VALUES: { value: EPerformanceSummaryFlag; i18nTitle: string }[] = [
  { value: EPerformanceSummaryFlag.SUMMARY_COLUMN, i18nTitle: 'performance.marketCapSelect.all' },
  { value: EPerformanceSummaryFlag.RESULTS_COLUMN, i18nTitle: 'performance.marketCapSelect.3b' },
]

export const SUMMARY_FLAG_DEFAULT_VALUE = SUMMARY_FLAGS_VALUES[1].value
